<template>
  <!-- SIMULADOR DATA INPUT-->
  <pz-simulators-template>
    <el-form
      slot="simulador"
      status-icon
      :model="form"
      :rules="validation"
      label-position="top"
    >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="monto"
            :error="errors.get('monto')"
          >
            <span>{{ monto_label }} </span>
            <pz-input-coint
              v-model.number="form.monto"
              type="text"
              :step="producto.stepMonto"
              :coint="producto.moneda_solicitud.symbol"
              :min="producto.min_monto"
              :max="producto.max_monto"
              @change="$nextTick(emitChange)"
              @input="$nextTick(handleInputNumber)"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="plazo"
            :error="errors.get('plazo')"
          >
            <span>{{ $t('forms_flujo.PzSimulator.how_many_fee') }} </span>
            <el-input-number
              v-model="form.plazo"
              :step="producto.stepCuota"
              :min="producto.min_cuota"
              :max="producto.max_cuota"
              @change="$nextTick(emitChange)"
              @input="handleInput"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col
          v-if="producto.needsSelds"
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="trabajadores"
            :error="errors.get('trabajadores')"
          >
            <span>{{ $t('forms_flujo.PzSimulator.annual_sells') }}</span>
            <el-select
              v-model="form.trabajadores"
              placeholder="Ventas anuales"
              @change="$nextTick(emitChange)"
              @input="handleInput"
            >
              <el-option
                v-for="worker in workers"
                :key="worker.id"
                :label="worker.workwers_range"
                :value="worker.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          v-if="producto.needsFirstDate"
          :xl="{span: 6}"
          :lg="{span: 6}"
          :md="{span: 6}"
          :sm="{span: 12}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="fecha_vencimiento"
            :error="errors.get('fecha_vencimiento')"
            class="bordered"
          >
            <span>
              {{ fechapago_label }}
            </span>
            <el-date-picker
              v-model="form.fecha_vencimiento"
              type="date"
              :span="2"
              placeholder="Seleccione Fecha"
              :picker-options="pickerOptions"
              format="dd-MM-yyyy"
              value-format="dd-MM-yyyy"
              @change="$nextTick(emitChange)"
              @input="$nextTick(handleInput)"
            />
          </el-form-item>
        </el-col>
        <el-col
          v-if="producto.needsFirstDate"
          :xl="{span: 6}"
          :lg="{span: 6}"
          :md="{span: 6}"
          :sm="{span: 12}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="emision"
            :error="errors.get('emision')"
          >
            <span>{{ $t('forms_flujo.PzSimulator.emission_date') }}</span>
            <el-date-picker
              v-model="form.emision"
              type="date"
              :span="2"
              placeholder="Seleccione Fecha"
              format="dd-MM-yyyy"
              :picker-options="pickerOptionsEmision"
              value-format="dd-MM-yyyy"
              @change="$nextTick(emitChange)"
              @input="$nextTick(handleInput)"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <comment :is="'pz-oferta-'+elOfertaView" />
    </el-form>
  </pz-simulators-template>
</template>
<script>

    import baseSimulator from "./baseSimulator";
    import PzSimulators from "@/components/Flujo/Templates/PzSimulatorsTemplate";
    import PzSimulatorsTemplate from "@/components/Flujo/Templates/PzSimulatorsTemplate";
    export default {
        name: 'PzDataInputSimulator',
        components: {PzSimulatorsTemplate,PzSimulators},
        mixins: [baseSimulator],

        data() {
            return {

            }
        },
        methods: {
            handleInputNumber() {
                //  this.
            }
        }
    }

</script>
