<template>
  <el-row class="text-center">
    <el-col
      :span="20"
      :offset="2"
    >
      <i18n
        :path="myForm.form.plazo===1?'forms_flujo.oferta_solicitud_default.offer_line':'forms_flujo.oferta_solicitud_default.offer_line_plural'"
        :choice="myForm.form.plazo"
        tag="label"
        class="pz-offer-color-primary pz-font-size-lg pz-large-margin"
      >
        <label
          class="pz-offer-color-secondary pz-font-size-xl"
          place="monto_oferta"
        >
          {{
            myForm.form.monto_oferta|currency(myForm.producto.moneda_prestamo.symbol, 0,{spaceBetweenAmountAndSymbol: true})
          }}
        </label>
        <label
          class="pz-offer-color-secondary pz-font-size-lg"
          place="plazo"
        >
          {{ myForm.form.plazo }}
        </label>
        <label
          place="monto-cuota"
          class="pz-offer-color-secondary pz-font-size-xl"
        >&nbsp;
           {{ getMontoCuota() }}
        </label>
        <b place="term_type">
          {{ myForm.producto.term_type.name }}
        </b>
      </i18n>
      <br>
      <span
        class="pz-font-size-sm text-center"
      >
         {{ $t('forms_flujo.pz_simulators_vehiculo.msg3') }}
      </span>
    </el-col>
  </el-row>
</template>

<script>

import PzOfertaSolicitudBase from "@/components/Flujo/Simuladores/Ofertas/baseOferta";

export default {
    name: "PzOfertaSolicitudBase",
    mixins: [PzOfertaSolicitudBase],

}
</script>
