<script>
    export default {
        name: "PzOfertaSolicitudDefault",
        inject: ['myForm'],
        methods: {
            getMontoCuota() {
                if (this.myForm.form.cuota_sugerida === 0) {
                    return '...'
                } else {
                    return this.$options.filters.currency(this.myForm.form.cuota_sugerida,
                        this.myForm.producto.moneda_prestamo.symbol, 0)
                }
            }
        },

    }
</script>